import { post } from './methods';

interface IAuthSign {
	token: string;
}

export const AuthApi = {
	confirmEmail: post<IAuthSign>(`/email-confirmation`),
	signUp: post<IAuthSign>('/auth/register'),
	login: post<IAuthSign>('/auth/login'),
	resetPassword: post('/auth/password/reset'),
	confirmPassword: post<IAuthSign>('/auth/password/confirm'),
	changePassword: post('/auth/password/change'),
	changeProfileData: post('/profile/change'),
	completeRegistration: (
		email: string,
		confirmCode: string,
		data: {
			firstName: string;
			lastName: string;
			country: string;
			password: string;
		},
	) => {
		const url = `/auth/complete-registration?email=${encodeURIComponent(email)}&confirmCode=${encodeURIComponent(confirmCode)}`;
		return post<void>(url)(data);
	},
};
